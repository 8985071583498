#privacy-policy {
    margin: 40px auto;
    width: 1100px;
    max-width: 90vw;
    color: white;
    font-weight: 300;
    text-align: justify;
    .pp-header {
        font-weight: 600;
    }
    p {
        text-align: justify;
    }
    .cookie {
        text-decoration: underline;
    }
    table {
        border:1px solid #b3adad;
        border-collapse:collapse;
        border-spacing:3px;
        padding:5px;
        margin-bottom: 50px;
    }
    table th {
        border:1px solid #b3adad;
        padding:5px;
    }
    table td {
        vertical-align: top;
        border:1px solid #b3adad;
        text-align:justify;
        padding:15px;
    }
}