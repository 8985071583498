:root {
    --main-bg-color: #151515;
    --main-shadow-color: rgba(25,27,31,0.48);
    --active-color: #c70d0d;
    --inactive-color: #0a0806;
    --active-text-color: #151515;
    --background-semi-color: #2b2a29;
    --header-color: rgba(21,21,21,0.7);
    --header-shadow-color: rgba(25,27,31,0.57);
    --btn-special-background: #188079;
    --btn-special-text: #dfdcd9;
    --marker-background: #151515;
    --marker-text: #fff;
    --normal-link-color: #6fa7b4;
    --report-link-color: #e5295b;
    --accept-link-color: #097969;
}